import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { register } from './serviceWorkerRegistration';
import * as Sentry from '@sentry/react';

// Initialize Sentry only in production
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN || 'https://547995627463d917d69d04c716b91888@o1418230.ingest.us.sentry.io/4506715220934656',
    environment: process.env.NODE_ENV,
    debug: false,
    tracesSampleRate: 0.2, // Reduced sample rate for better performance
    beforeSend(event) {
      // Don't send errors if they're network related or CSP violations
      if (event.exception?.values?.[0]?.type === 'NetworkError' ||
          event.exception?.values?.[0]?.type === 'SecurityError' ||
          event.exception?.values?.[0]?.value?.includes('Content Security Policy')) {
        return null;
      }
      return event;
    },
    integrations: [
      Sentry.browserTracingIntegration(),
    ],
    tracePropagationTargets: [
      /^https:\/\/[^\/]+\.onarriv\.io/,
      /^https:\/\/[^\/]+\.goniyo\.com/
    ],
  });
}

// Register service worker only in production
if (process.env.NODE_ENV === 'production') {
  register();
}

// Initialize root with error handling
const rootElement = document.getElementById('root');
if (!rootElement) {
  throw new Error('Failed to find the root element');
}

const root = ReactDOM.createRoot(rootElement);

// Render with error boundary and strict mode
try {
  root.render(
    <StrictMode>
      <App />
    </StrictMode>
  );
} catch (error) {
  console.error('Failed to render application:', error);
  // Attempt to render error state
  root.render(
    <div style={{ padding: 20, textAlign: 'center' }}>
      <h1>Something went wrong</h1>
      <p>Please try refreshing the page</p>
    </div>
  );
}

// Only enable web vitals reporting in production
if (process.env.NODE_ENV === 'production') {
  reportWebVitals(metric => {
    // Send to analytics
    console.log(metric);
  });
}
