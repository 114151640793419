import { useEffect } from 'react';

export const useContextMenu = () => {
  useEffect(() => {
    const handleContextMenu = (event: MouseEvent) => {
      event.preventDefault(); // Prevents the default context menu
    };

    // Add event listener for 'contextmenu', which includes right-clicks and long-presses
    document.addEventListener('contextmenu', handleContextMenu);

    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);
};
