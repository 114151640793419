import { useEffect } from 'react';
import VConsole from 'vconsole';

export const useVConsole = (enable: boolean = true) => {
  useEffect(() => {
    if (!enable) return;
    
    // Initialize vConsole but keep it hidden by default
    const vConsole = new VConsole();
    vConsole.hideSwitch();
    
    return () => {
      // Cleanup vConsole on unmount
      vConsole.destroy();
    };
  }, [enable]);
};
