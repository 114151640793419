import { SxProps, Theme } from '@mui/material/styles';

export const flightContainerStyles: SxProps<Theme> = {
  overflow: 'auto',
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
} as const;

export const getHeaderStyles = (premiumUser: boolean, windowHeight: number, windowWidth: number): Record<string, any> => ({
  borderBottom: 'none',
  backgroundImage: 'url(https://oa-flight-assets.s3.ap-south-1.amazonaws.com/offer/top-container.png)',
  backgroundRepeat: 'no-repeat',
  backgroundSize: premiumUser ? '100%' : 'cover',
  backgroundPosition: premiumUser ? 'none' : 'center',
  minHeight: premiumUser
    ? windowHeight === 667 && windowWidth === 375
      ? '26vh'
      : '30vh'
    : windowHeight === 667 && windowWidth === 375
    ? '205px'
    : '300px',
  position: 'relative',
} as const);

export const contentContainerStyles: SxProps<Theme> = {
  position: 'relative',
  zIndex: 1,
  marginTop: '-50px',
  paddingBottom: '100px',
} as const;

export const searchContainerStyles: SxProps<Theme> = {
  backgroundColor: '#fff',
  borderRadius: '12px',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
  padding: '20px',
  marginBottom: '20px',
} as const;

export const headerSx: SxProps<Theme> = {
  width: '100%',
  position: 'relative',
} as const;
