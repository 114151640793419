import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Grid, Box, Typography, Card } from '@mui/material';
import FlightCard from './FlightCard';
import { IFlight } from '../../type';
import { VariableSizeList } from 'react-window';
import { OAImage, OASwipeableDrawer } from '../../components';
import { useDispatch, useSelector } from '../../store';
import { getFeatureFlag } from '../../store/slices/featuresSlice';
import { getUserInfo } from '../../store/slices/userInfoSlice';
import { getAuthInfo } from '../../store/slices/authSlice';
import FlightLabel from './FlightLabel';
import { getUserInputState, setTravelDetail } from '../../store/slices/userInputSlice';
import { eventsTracker } from '../../utils/ctEventsTracking';
import posthog from 'posthog-js';
import { sortFlights } from '../../utils/sortFlights';
import { getOffersState } from '../../store/slices/offersSlice';
import { getSearchFlightState } from '../../store/slices/searchFlightSlice';
import { getComparativePrices } from '../../utils/getComparativePrices';
import { useNavigate } from 'react-router-dom';
import ComparedFlights from './ComparedFlights';
import { IDurationInfo } from '../../type/IDurationInfo';
import { useOffers } from '../../hooks/useOffers';
import { TripType } from '../Flight/types';

const layoutProps = {
  display: 'flex',
  flexWrap: 'nowrap',
  overflowX: 'auto',
  overflowY: 'hidden',
  minWidth: '100%', // Adjust based on your layout
  scrollbarWidth: 'none' /* For Firefox */,
  '&::-webkit-scrollbar': {
    display: 'none' /* For Chrome, Safari and Edge */,
  },
  // Add more styling as needed to match your layout
};

interface FlightsListProps {
  flights: IFlight[];
  onFlightSelect: (flight: IFlight) => void;
  tripType: 'R' | 'O';
  isInternational: boolean;
  selectedFlight: IFlight;
  fastestFlight: IFlight;
  lowestPriceFlight: IFlight;
  isFastestAndLowestSame: boolean;
  nearbyAirports: any;
  cheapestNonStopFlight?: IFlight;
  filters?: any;
  sort?: any;
}

const FlightsList: React.FC<FlightsListProps> = ({
  flights,
  onFlightSelect,
  tripType,
  isInternational,
  fastestFlight,
  lowestPriceFlight,
  isFastestAndLowestSame,
  nearbyAirports,
  cheapestNonStopFlight,
  filters,
  sort,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { travelDetail } = useSelector<any>(getUserInputState);
  const listRef = useRef<VariableSizeList>(null);
  const { isCoinsApplied } = useSelector(getUserInputState);
  const { data: offers } = useSelector(getOffersState);
  const { coins, tags } = useSelector(getUserInfo);
  const { burnCoinsFeat, noConvenienceFeeBanner, esimOffer, premiumUserFeat, convenienceFeeTag } =
    useSelector(getFeatureFlag);
  const { campaigns } = useSelector(getAuthInfo);
  const { flightPrices } = useSelector(getSearchFlightState);
  const [selectedGroup, setSelectedGroup] = useState<IFlight[]>([]);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [startTime, setStartTime] = useState(Date.now());

  const isPremiumUser = Array.isArray(tags) && tags?.includes('NIYO_GOLD_PREMIUM_USER');
  const isPremiumUserSilver = Array.isArray(tags) && tags?.includes('NIYO_SILVER_PREMIUM_USER');
  const isDomesticOrInternationalOneWay = tripType === 'O' && (isInternational || !isInternational);
  const { applicableOffers } = useOffers({
    currentPage: ["SRP"],
    isInternationalRoute: isInternational,
    tripType: travelDetail?.tripType === 'O' ? 'ONE_WAY' : 'ROUND_TRIP' as TripType,
    offers: offers,
  })

  const handleSimilarFlightClick = (group: IFlight[], tripType: 'R' | 'O') => {
    setSelectedGroup(group);
    setOpenDrawer(true);
  };

  const { similarFlights, unsimilarFlights } = useMemo(() => {
    // Memoize duration calculation per flight
    const flightDurations = new Map<string, number>();

    const getTotalDuration = (flight: IFlight) => {
      if (flightDurations?.has(flight?.id)) {
        return flightDurations?.get(flight?.id)!;
      }

      const calculateDuration = (durationInfo: IDurationInfo) => {
        const hours = parseInt(durationInfo?.display?.split('h')?.[0] || '0', 10);
        const minutes = parseInt(durationInfo?.display?.split('h')?.[1]?.split('m')?.[0]?.trim() || '0', 10);
        return hours * 60 + minutes;
      };
      let total = calculateDuration(flight?.durationInfo);
      if (flight?.returnDurationInfo) {
        total += calculateDuration(flight?.returnDurationInfo);
      }

      flightDurations?.set(flight?.id, total);
      return total;
    };

    const groupedFlights = flights?.reduce((acc, flight) => {
      if (!flight?.segments || flight?.segments?.length <= 1) return acc;

      const airlineCode = flight?.segments[0]?.airline?.code;
      const price = flight?.price?.value;
      const key = `${airlineCode}-${price}`;
      acc[key] ||= [];
      acc[key]?.push(flight);
      return acc;
    }, {} as { [key: string]: IFlight[] });

    // Batch sorting with precomputed durations
    if (groupedFlights) {
      for (const groupKey in groupedFlights) {
        groupedFlights[groupKey]?.sort((a, b) =>
          getTotalDuration(a) - getTotalDuration(b)
        );
      }
    }

    // Just identify similar groups and unsimilar flights without sorting
    const similar = Object.values(groupedFlights)?.filter((group) => group?.length > 1);
    const similarFlightIds = new Set(similar?.flat()?.map((flight) => flight?.id));
    const unsimilar = flights?.filter((flight) => !similarFlightIds?.has(flight?.id));

    return { similarFlights: similar, unsimilarFlights: unsimilar };
  }, [flights]);

  // combined sorted array of flight items
  const sortedFlightItems = useMemo(() => {
    const items: Array<{ type: 'similar' | 'unsimilar'; flight: IFlight; group?: IFlight[] }> = [];

    // adding all flights to items array
    similarFlights?.forEach((group) => {
      items.push({
        type: 'similar',
        flight: group[0],
        group: group,
      });
    });

    unsimilarFlights?.forEach((flight) => {
      items.push({
        type: 'unsimilar',
        flight: flight,
      });
    });

    // converting items array to flights array for sorting
    const flightsToSort = items?.map((item) => item?.flight);

    // sorting flights using existing sortFlights function
    const sortedFlights = sortFlights(flightsToSort, sort || 'plh');

    // map back to original format with type and group info
    return sortedFlights?.map((flight) => items?.find((item) => item?.flight?.id === flight?.id)!);
  }, [similarFlights, unsimilarFlights, flights, filters, sort]);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.resetAfterIndex(0);
    }
  }, [isCoinsApplied, campaigns, coins, burnCoinsFeat]);

  useEffect(() => {
    setStartTime(Date.now());
  }, []);

  const handleCloseBottomDrawer = () => {
    const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);

    eventsTracker(
      {
        flowName: 'Flight',
        screenName: 'SRP',
        ctaAction: 'SRP_MoreOptionsClose',
        screenDuration: totalScreenDuration?.toString(),
        UserAction: {
          userAction: 'User has closes the bottom sheet (tap out/close icon)',
        },
      },
      posthog
    );
    setOpenDrawer(false);
  };

  const isCheapestAndFastest = (flight: IFlight) =>
    cheapestNonStopFlight &&
    flight?.id === cheapestNonStopFlight?.id &&
    flight?.id !== lowestPriceFlight?.id &&
    fastestFlight &&
    flight?.id === fastestFlight?.id &&
    isInternational;

  const renderFlightLabels = (flight: IFlight, international: boolean) => (
    <>
      {flight?.id === fastestFlight?.id && flight?.id === lowestPriceFlight?.id ? (
        <>
          <FlightLabel
            bgColor="#215039"
            icon="percent.svg"
            text="Cheapest"
            customStyles={{ right: '90px', zIndex: 10 }}
          />
          <FlightLabel
            bgColor="#F0935F"
            icon="fastest.svg"
            text="Fastest"
            customStyles={{ right: '16px', zIndex: 10 }}
          />
        </>
      ) : (
        <>
          {lowestPriceFlight && flight?.id === lowestPriceFlight?.id && (
            <FlightLabel
              bgColor="#215039"
              icon="percent.svg"
              text="Cheapest"
              customStyles={{ right: '16px', zIndex: 10 }}
            />
          )}
          {cheapestNonStopFlight &&
            flight?.id === cheapestNonStopFlight?.id &&
            flight?.id !== lowestPriceFlight?.id && (
              <FlightLabel
                bgColor="#215039"
                icon="percent.svg"
                text="Cheapest Non-Stop"
                customStyles={{ right: isCheapestAndFastest(flight) ? '90px' : '16px', zIndex: 10 }}
              />
            )}
          {fastestFlight && flight?.id === fastestFlight?.id && isInternational && (
            <FlightLabel
              bgColor="#F0935F"
              icon="fastest.svg"
              text="Fastest"
              customStyles={{ right: '16px', zIndex: 10 }}
            />
          )}
        </>
      )}
    </>
  );

  const comparativePrices = useMemo(
    () => getComparativePrices(flightPrices, sortedFlightItems, travelDetail?.tripStart?.date),
    [flightPrices, sortedFlightItems, travelDetail?.tripStart?.date]
  );

  const handleComparativePriceClick = (date: string) => {
    const { from, to, travellerCount, cabinType, tripType, fareGroup } = travelDetail;

    dispatch(
      setTravelDetail({
        ...travelDetail,
        tripStart: { date: date },
      })
    );

    const { adult = 0, child = 0, infant = 0 } = travellerCount || {};
    const traveller = `adults=${adult}&childs=${child}&infants=${infant}`;

    const fromTo =
      `${from?.iata}` +
      `&fromCity=${from?.city}` +
      `&to=${to?.iata}` +
      `&toCity=${to?.city}` +
      `&fromDate=${date}` +
      `${fareGroup ? `&fareGroup=${fareGroup}` : ''}`;

    navigate(`/results?tripType=${tripType}&cabin=${cabinType}&${traveller}&from=${fromTo}`);
  };

  const moreOptionsText = (ft: any) => {
    const count = ft?.group?.length - 1;
    return `See ${count} option${count > 1 ? 's' : ''} at same price`;
  };

  return (
    <Grid
      height={'calc(100vh - 104px)'}
      overflow={tripType === 'R' && !isInternational ? 'hidden' : 'scroll'}
      px="0px"
      bgcolor="#F4F6F5"
      pt={tripType === 'R' && !isInternational ? '0px' : '16px'}
    >
      <Grid item xs={12} sx={{ height: '100%', overflowY: 'auto' }} pt="10px">
        {/* First 2 flights */}
        {sortedFlightItems?.slice(0, 2)?.map((flightItem, index) => (
          <Box
            key={flightItem?.flight?.id}
            mx="16px"
            mt="8px"
            mb={sortedFlightItems?.length <= 2 && index === 1 ? '80px' : '20px'} //conditional margin if these are the only items
            position="relative"
          >
            {renderFlightLabels(flightItem?.flight, isInternational)}
            <FlightCard
              data={flightItem?.flight}
              onClick={() => onFlightSelect(flightItem?.flight)}
              tripType={tripType}
              isInternational={isInternational}
              nearbyAirports={nearbyAirports}
              sx={{ borderBottomRightRadius: flightItem?.type === 'similar' ? '0px' : undefined }}
            />
            {flightItem?.type === 'similar' &&
              flightItem?.group &&
              flightItem?.group?.length > 1 && (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Box
                    sx={{
                      backgroundColor: '#009E82',
                      color: 'white',
                      padding: '4px 8px',
                      borderBottomLeftRadius: '8px',
                      borderBottomRightRadius: '8px',
                      borderTopRightRadius: '0px',
                      borderTopLeftRadius: '0px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    onClick={() => handleSimilarFlightClick(flightItem?.group!, tripType)}
                  >
                    <Typography variant="body4" color="white">
                      {moreOptionsText(flightItem)}
                    </Typography>
                    <OAImage
                      src="right-chevron.svg"
                      folder="icons"
                      alt="right"
                      width="16px"
                      height="16px"
                    />
                  </Box>
                </Box>
              )}
          </Box>
        ))}
        {applicableOffers?.length > 0 && false ? (
          <Box sx={{ marginBottom: '15px' }}>
            {applicableOffers?.map((offer: any) => (
              <Box key={offer?.id}>
                <img src={offer?.bannerImage} alt="offer" width="100%" />
              </Box>
            ))}
          </Box>
        ) : (
          <>
            {noConvenienceFeeBanner &&
              (isPremiumUser || isPremiumUserSilver) &&
              premiumUserFeat && (
                <Box sx={{ marginBottom: '15px' }}>
                  <OAImage
                    src={
                      isPremiumUserSilver
                        ? 'niyo-convenience-silver.svg'
                        : 'niyo-convenience-gold.svg'
                    }
                    folder="offer"
                    alt="no-convenience-fee"
                    width="100%"
                  />
                </Box>
              )}
            {convenienceFeeTag && !premiumUserFeat && (
              <Box sx={{ marginBottom: '15px' }}>
                <OAImage
                  src="no-convenience-fee.svg"
                  folder="icons"
                  alt="no-convenience-fee"
                  width="100%"
                />
              </Box>
            )}
          </>
        )}

        {/* Remaining flights */}
        {sortedFlightItems?.slice(2, 4)?.map((flightItem, index) => (
          <Box
            key={flightItem?.flight?.id}
            mx="16px"
            mb={
              sortedFlightItems?.length <= 5 && index === sortedFlightItems?.slice(2, 5)?.length - 1
                ? '80px'
                : '20px'
            } //conditional margin if these are the last items
            position="relative"
          >
            {renderFlightLabels(flightItem?.flight, isInternational)}
            <FlightCard
              data={flightItem?.flight}
              onClick={() => onFlightSelect(flightItem?.flight)}
              tripType={tripType}
              isInternational={isInternational}
              nearbyAirports={nearbyAirports}
              sx={{ borderBottomRightRadius: flightItem?.type === 'similar' ? '0px' : undefined }}
            />
            {flightItem?.type === 'similar' &&
              flightItem?.group &&
              flightItem?.group?.length > 1 && (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Box
                    sx={{
                      backgroundColor: '#009E82',
                      color: 'white',
                      padding: '4px 8px',
                      borderBottomLeftRadius: '8px',
                      borderBottomRightRadius: '8px',
                      borderTopRightRadius: '0px',
                      borderTopLeftRadius: '0px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    onClick={() => handleSimilarFlightClick(flightItem?.group!, tripType)}
                  >
                    <Typography variant="body4" color="white">
                      {moreOptionsText(flightItem)}
                    </Typography>
                    <OAImage
                      src="right-chevron.svg"
                      folder="icons"
                      alt="right"
                      width="16px"
                      height="16px"
                    />
                  </Box>
                </Box>
              )}
          </Box>
        ))}

        {comparativePrices?.length > 0 && isDomesticOrInternationalOneWay && (
          <ComparedFlights
            comparativePrices={comparativePrices}
            travelDetail={travelDetail}
            layoutProps={layoutProps}
            handleComparativePriceClick={handleComparativePriceClick}
          />
        )}

        {sortedFlightItems?.slice(4, 5)?.map((flightItem, index) => (
          <Box key={flightItem?.flight?.id} mx="16px" mb="20px" position="relative">
            {renderFlightLabels(flightItem?.flight, isInternational)}
            <FlightCard
              data={flightItem?.flight}
              onClick={() => onFlightSelect(flightItem?.flight)}
              tripType={tripType}
              isInternational={isInternational}
              nearbyAirports={nearbyAirports}
              sx={{ borderBottomRightRadius: flightItem?.type === 'similar' ? '0px' : undefined }}
            />
            {flightItem?.type === 'similar' &&
              flightItem?.group &&
              flightItem?.group?.length > 1 && (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Box
                    sx={{
                      backgroundColor: '#009E82',
                      color: 'white',
                      padding: '4px 8px',
                      borderBottomLeftRadius: '8px',
                      borderBottomRightRadius: '8px',
                      borderTopRightRadius: '0px',
                      borderTopLeftRadius: '0px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    onClick={() => handleSimilarFlightClick(flightItem?.group!, tripType)}
                  >
                    <Typography variant="body4" color="white">
                      {moreOptionsText(flightItem)}
                    </Typography>
                    <OAImage
                      src="right-chevron.svg"
                      folder="icons"
                      alt="right"
                      width="16px"
                      height="16px"
                    />
                  </Box>
                </Box>
              )}
          </Box>
        ))}

        {/* ESIM Banner */}
        {applicableOffers?.length > 0 && false ? (
          <>
            {noConvenienceFeeBanner &&
              (isPremiumUser || isPremiumUserSilver) &&
              premiumUserFeat && (
                <Box sx={{ marginBottom: '15px' }}>
                  <OAImage
                    src={
                      isPremiumUserSilver
                        ? 'niyo-convenience-silver.svg'
                        : 'niyo-convenience-gold.svg'
                    }
                    folder="offer"
                    alt="no-convenience-fee"
                    width="100%"
                  />
                </Box>
              )}
            {convenienceFeeTag && !premiumUserFeat && (
              <Box sx={{ marginBottom: '15px' }}>
                <OAImage
                  src="no-convenience-fee.svg"
                  folder="icons"
                  alt="no-convenience-fee"
                  width="100%"
                />
              </Box>
            )}
          </>
        ) : (
          esimOffer &&
          isInternational && (
            <Box mb="15px">
              <OAImage src="esim-srp.svg" folder="offer" alt="esim-banner" width="100%" />
            </Box>
          )
        )}

        {sortedFlightItems?.slice(5)?.map((flightItem, index) => (
          <Box
            key={flightItem?.flight?.id}
            mx="16px"
            mb={index === sortedFlightItems?.slice(5)?.length - 1 ? '80px' : '20px'} //conditional margin if these are the last items
            position="relative"
          >
            {renderFlightLabels(flightItem?.flight, isInternational)}
            <FlightCard
              data={flightItem?.flight}
              onClick={() => onFlightSelect(flightItem?.flight)}
              tripType={tripType}
              isInternational={isInternational}
              nearbyAirports={nearbyAirports}
              sx={{ borderBottomRightRadius: flightItem?.type === 'similar' ? '0px' : undefined }}
            />
            {flightItem?.type === 'similar' &&
              flightItem?.group &&
              flightItem?.group?.length > 1 && (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Box
                    sx={{
                      backgroundColor: '#009E82',
                      color: 'white',
                      padding: '4px 8px',
                      borderBottomLeftRadius: '8px',
                      borderBottomRightRadius: '8px',
                      borderTopRightRadius: '0px',
                      borderTopLeftRadius: '0px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    onClick={() => handleSimilarFlightClick(flightItem?.group!, tripType)}
                  >
                    <Typography variant="body4" color="white">
                      {moreOptionsText(flightItem)}
                    </Typography>
                    <OAImage
                      src="right-chevron.svg"
                      folder="icons"
                      alt="right"
                      width="16px"
                      height="16px"
                    />
                  </Box>
                </Box>
              )}
          </Box>
        ))}
      </Grid>

      {/* Bottom Drawer */}
      <OASwipeableDrawer
        open={openDrawer}
        onClose={handleCloseBottomDrawer}
        onOpen={() => setOpenDrawer(true)}
        title="Same price options"
        isContainer={false}
        maxHeight={90}
      >
        <Box bgcolor="#F4F6F5">
          {selectedGroup?.map((flight) => (
            <Box key={flight?.id} sx={{ marginBottom: '15px' }} px="16px" mt="8px">
              {flight?.id === fastestFlight?.id && flight?.id === lowestPriceFlight?.id ? (
                <>
                  <FlightLabel
                    bgColor="#215039"
                    icon="percent.svg"
                    text="Cheapest"
                    customStyles={{ top: '90px', zIndex: 99, right: '110px' }}
                  />
                  <FlightLabel
                    bgColor="#F0935F"
                    icon="fastest.svg"
                    text="Fastest"
                    customStyles={{ top: '90px', zIndex: 99, right: '32px' }}
                  />
                </>
              ) : (
                <>
                  {lowestPriceFlight && flight?.id === lowestPriceFlight?.id && (
                    <FlightLabel
                      bgColor="#215039"
                      icon="percent.svg"
                      text="Cheapest"
                      customStyles={{ top: '90px', zIndex: 99, right: '32px' }}
                    />
                  )}
                  {cheapestNonStopFlight &&
                    flight?.id === cheapestNonStopFlight?.id &&
                    flight?.id !== lowestPriceFlight?.id && (
                      <FlightLabel
                        bgColor="#215039"
                        icon="percent.svg"
                        text="Cheapest Non-Stop"
                        customStyles={{
                          top: '90px',
                          zIndex: 99,
                          right: isCheapestAndFastest(flight) ? '110px' : '32px',
                        }}
                      />
                    )}
                  {fastestFlight && flight?.id === fastestFlight?.id && isInternational && (
                    <FlightLabel
                      bgColor="#F0935F"
                      icon="fastest.svg"
                      text="Fastest"
                      customStyles={{ top: '90px', zIndex: 99, right: '32px' }}
                    />
                  )}
                </>
              )}
              <FlightCard
                data={flight}
                onClick={() => {
                  const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);
                  eventsTracker(
                    {
                      flowName: 'Flight',
                      screenName: 'SRP',
                      ctaAction: 'SRP_MoreOptionsSelect',
                      screenDuration: totalScreenDuration?.toString(),
                      UserAction: {
                        userAction: 'User has selected a flight from the bottom sheet',
                      },
                    },
                    posthog
                  );
                  onFlightSelect(flight);
                }}
                tripType={tripType}
                isInternational={isInternational}
                nearbyAirports={nearbyAirports}
              />
            </Box>
          ))}
        </Box>
      </OASwipeableDrawer>
    </Grid>
  );
};

export default FlightsList;
