import { useEffect } from 'react';
import { usePostHog } from 'posthog-js/react';
import { eventsTracker } from './utils/ctEventsTracking';
import { useDispatch, useSelector } from './store';
import { getAuthInfo, setVisibility } from './store/slices/authSlice';
import PostHog from 'posthog-js';

interface VisibilityObserverProps {
  children: React.ReactNode;
}

const VisibilityObserver: React.FC<VisibilityObserverProps> = ({ children }) => {
  const posthog = usePostHog();
  const dispatch = useDispatch();
  const { visibility, user } = useSelector(getAuthInfo);

  useEffect(() => {
    // Define the event listener as a function
    function handleVisibilityChange() {
      console.log('handleVisibilityChange called!!!', document.visibilityState, visibility);
      if (document.visibilityState === 'visible' && visibility) {
        console.log('if --- visible first time only');
        dispatch(setVisibility(false));
        const isProduction = window.location.href.includes('goniyo.com');

        if (isProduction) {
          console.log('PostHog init');
          PostHog.init('phc_iLDvYsGnpVG8abwRSx2CfYZDYFWUvg0DZhB4e3cyaqG', {
            api_host: 'https://app.posthog.com',
            disable_session_recording: true,
            autocapture: false,
            capture_pageview: false,
          });
          // setTimeout(() => {
          //   posthog?.identify(user?.userId, {
          //     email: user?.email,
          //     name: user?.fullName,
          //   });
          // }, 300);
        }

        setTimeout(() => {
          eventsTracker(
            {
              flowName: 'Flight',
              screenName: 'Home',
              ctaAction: 'View',
              screenDuration: '1',
              UserAction: {
                userAction: 'User visit home page',
              },
            },
            posthog
          );
        }, 1000);

        setTimeout(() => {
          eventsTracker(
            {
              flowName: 'Flight',
              screenName: 'Home',
              ctaAction: 'ViewStay',
              screenDuration: '8',
            },
            posthog
          );
        }, 8000);

        console.log('Removed visibilitychange');
        // Remove the event listener once the condition is met
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      }
    }

    // Add the event listener
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup function to remove the event listener
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return <>{children}</>;
};

export default VisibilityObserver;
