import { createApi } from "@reduxjs/toolkit/query/react";
import { NIYO_OFFER_DISCOUNT } from "../store/apiUrls";
import { baseApi } from "./baseApi";

export const offersApi = createApi({
  reducerPath: 'offersApi',
  baseQuery: baseApi,
  tagTypes: ['Offers'],
  endpoints: (builder) => ({
    getOffers: builder.query({
      query: () => ({
        url: NIYO_OFFER_DISCOUNT,
        method: 'GET'
      }),
    })
  })
});

export const { useLazyGetOffersQuery } = offersApi;