import { Card, Typography } from '@mui/material';
import { OAImage } from '..';
import { useNearbyAirportDistance } from '../../hooks/useNearbyAirportDistance';

const NearbyAirportBanner = ({ message, data }: { message: string | undefined; data: any }) => {
  const display = useNearbyAirportDistance(data);
  
  if (!message && (!display || !display?.distance || !display?.iata)) return null;

  const content = message
    ? message
    : `${display?.message} airport is <strong>${display?.distance}</strong> away from <strong>${display?.iata}</strong>`;

  return (
    <Card sx={{ mt: '10px', display: 'flex', p: '10px' }}>
      <OAImage
        sx={{
          alignSelf: 'flex-start',
        }}
        src="location-dot.svg"
        alt="location"
        folder="icons"
      />
      <Typography ml="10px" variant="body3" dangerouslySetInnerHTML={{ __html: content }} />
    </Card>
  );
};

export default NearbyAirportBanner;
