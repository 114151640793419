import { useEffect } from 'react';
import { useDispatch } from '../../../store';
import { setLatLong } from '../../../store/slices/userInfoSlice';
import { parseObject } from '../../../utils';

export const useLocation = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        if (
          window.flutter_inappwebview &&
          window.niyo_location &&
          typeof window.niyo_location === 'function'
        ) {
          const res = await window.niyo_location();
          const locationData = parseObject(res);
          dispatch(setLatLong(locationData));
        }
      } catch (error) {
        console.error('Error fetching location:', error);
      }
    };

    fetchLocation();
  }, [dispatch]);
};
