export const eventsTracker = (eventData: any, posthog?: any) => {
  console.log('Track eventData', eventData);
  if (
    window.flutter_inappwebview &&
    window.niyo_ct_event_tracking &&
    typeof window.niyo_ct_event_tracking === 'function'
  ) {
    console.log('niyo_ct_event_tracking', eventData);
    const stringifiedEventData = JSON.stringify(eventData);
    window.niyo_ct_event_tracking(stringifiedEventData);
    if (window.location.href.includes('goniyo.com')) {
      posthog.capture(eventData?.screenName + '_' + eventData?.ctaAction, eventData);
    }
  }
};
