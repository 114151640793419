import { Box, Card, CircularProgress, Typography } from "@mui/material"
import { OAButton, OAImage } from "../../components"
import { formatToINR } from "../../utils"

const CfarSection = ({
  cfarRefundAmount,
  cfarInsuranceData,
  isCfarAdded,
  handleUpdateAddOnServices,
  isLoadingAddOn,
  handleTandCClick
}: any) => {
  return (
    <Box my="30px">
      <Box mb="-10px">
        <OAImage src="wave.svg" folder="icons" alt="wave" width="100%" />
      </Box>{' '}
      <Box bgcolor="#E6EEFF" p="5px 20px">
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Box mr='6px'>
            <Typography color="#13387E" fontWeight={400}>
              No Questions Asked: <Box component="span" fontWeight={600}>Upto {formatToINR(cfarRefundAmount)}</Box> back instantly on cancellation
            </Typography>
          </Box>
          <Box>
            <OAImage
              src='tata-aig-logo2.png'
              alt='insur'
              sx={{ width: '100px' }}
            />
          </Box>
        </Box>
        <Box mt='10px'>
          <Box position='relative'>
            
            <Card sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              borderRadius: '15px',
              p: '12px'
            }}>
              <Typography variant="h2">{formatToINR(cfarInsuranceData?.totalAmount ?? cfarInsuranceData?.additionalInfo?.perTravellerInsurancePricing)}<Typography variant='body3' fontWeight={600}> / traveller</Typography></Typography>
              <OAButton
                onClick={handleUpdateAddOnServices}
                size="small"
                disabled={isLoadingAddOn}
                sx={{
                  color: isCfarAdded?.totalAmount ? '#14191F' : '#fff',
                  backgroundColor: isLoadingAddOn
                    ? '#E0E0E0'
                    : isCfarAdded?.totalAmount
                      ? '#FFE5E2'
                      : '#0A1017',
                  '&&:hover': {
                    backgroundColor: isLoadingAddOn
                      ? '#E0E0E0'
                      : isCfarAdded?.totalAmount
                        ? '#FFE5E2'
                        : '#0A1017',
                  },
                  '&&:focus': {
                    backgroundColor: isLoadingAddOn
                      ? '#E0E0E0'
                      : isCfarAdded?.totalAmount
                        ? '#FFE5E2'
                        : '#0A1017',
                  },
                  display: 'flex',
                  width: 'fit-content',
                  minWidth: '75px',
                }}
              >
                {
                  <Box display="flex" alignItems="center">
                    {isLoadingAddOn ? (
                      <CircularProgress size={20} sx={{ color: '#14191F' }} />
                    ) : (
                      <>
                        {!isCfarAdded?.totalAmount && (
                          <OAImage src="add-white.svg" folder="icons" alt="add" />
                        )}
                        <Typography
                          variant="body3"
                          sx={{
                            color: isCfarAdded?.totalAmount ? '#14191F' : '#fff',
                            ml: '5px',
                          }}
                        >
                          {isCfarAdded?.totalAmount ? 'Remove' : 'Add'}
                        </Typography>
                      </>
                    )}
                  </Box>
                }
              </OAButton>
            </Card>
            <Box mt="10px">
              <Typography variant="body3" color="text.secondary" mt="10px">
                Be stress-free and receive a refund on cancellation.{' '}
                <Typography
                  variant="body3"
                  color="green"
                  onClick={handleTandCClick}
                  sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                >
                  T&C
                </Typography>{' '}
                
              </Typography>
            </Box>
          </Box></Box>
      </Box>
      <Box sx={{ mt: '-2px' }}>
        <OAImage src="bottomwave.svg" folder="icons" alt="wave" width="100%" />
      </Box>{' '}
    </Box>
  )
}

export default CfarSection;