import { Box, Typography, SxProps } from '@mui/material';
import { OAImage } from './OAImage';

interface OAIconAndTextProps {
  iconSrc: string;
  text: string;
  isHtml?: boolean;
  bgColor?: string;
  containerSx?: SxProps;
  typographySx?: SxProps;
  iconSx?: SxProps;
}

const OAIconAndText = ({
  iconSrc,
  text,
  isHtml = false,
  bgColor,
  containerSx,
  typographySx,
  iconSx,
}: OAIconAndTextProps): JSX.Element => {
  return (
    <Box
      display="flex"
      alignItems="center"
      bgcolor={bgColor}
      p="8px 20px"
      sx={containerSx}
    >
      <Box mr="5px" sx={iconSx}>
        <OAImage src={iconSrc} folder="icons" alt="icon" width={20} height={16} maxWidth={20} maxHeight={20} />
      </Box>
      <Typography
        variant="body2"
        sx={{ 
          '& p': { my: 0 },
          '& strong': { fontWeight: 600 },
          ...typographySx
        }}
        {...(isHtml ? {
          dangerouslySetInnerHTML: { __html: text }
        } : {
          children: text
        })}
      />
    </Box>
  );
};

export default OAIconAndText;