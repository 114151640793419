import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { OAHeader, OASwitch } from '../../components';
import { HeaderActionFlight } from './FlightHeaderAction';
import { useDispatch, useSelector } from '../../store';
import RecentFlightSearch from './RecentFlightSearch';
import { getUserInfo, setMock } from '../../store/slices/userInfoSlice';
import SearchParameters from './SearchParameters';
import { getAuthInfo } from '../../store/slices/authSlice';
import { useGetBookingsListQuery } from '../../services/bookingsApi';
import CoinsBanner from './CoinsBanner';
import { setIsCoinsApplied } from '../../store/slices/userInputSlice';
import OffersBenefits from './OffersBenefits';
import { getFeatureFlag } from '../../store/slices/featuresSlice';
import { getOffersState } from '../../store/slices/offersSlice';
import SpecialOffers from './SpecialOffers';
import { flightContainerStyles, getHeaderStyles, contentContainerStyles, headerSx } from './styles';
import { useLocation } from './hooks/useLocation';
import { Booking, Campaign, FeatureFlags, UserInfo } from './types';
import { isTestEmailForProd } from '../../utils';

import { VERSION } from '../../version';

const Flight = () => {
  const dispatch = useDispatch();
  const { user, campaigns, token } = useSelector(getAuthInfo);
  const { data: bookings } = useGetBookingsListQuery({}, { skip: !user });
  const { isMock, coins } = useSelector<UserInfo>(getUserInfo);
  const [scrollTop, setScrollTop] = useState(true);
  const { burnCoinsFeat, premiumUserFeat } = useSelector<FeatureFlags>(getFeatureFlag);
  const { data: offers } = useSelector(getOffersState);

  // Use custom location hook
  useLocation();

  // Memoized values
  const isBannerDisplayed = useMemo(
    () => burnCoinsFeat || (campaigns as Campaign)?.eligibleCampaigns?.length > 0,
    [burnCoinsFeat, campaigns]
  );

  const activeBookingsCount = useMemo(() => {
    if (!bookings) return 0;
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    return bookings.filter(
      (booking: Booking) =>
        ['BOOKING_CONFIRMED', 'UPCOMING', 'BOOKING_IN_PROGRESS', 'PROCESSING_OFFLINE'].includes(
          booking.bookingStatus
        ) && new Date(booking.tripStartDate) >= today
    ).length;
  }, [bookings]);

  // Effects
  useEffect(() => {
    if (coins > 9) {
      dispatch(setIsCoinsApplied(true));
    }
  }, [coins, dispatch]);

  useEffect(() => {
    console.log(`Version ${VERSION}`);
  }, []);

  // Event handlers
  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      dispatch(setMock(event.target.checked));
    },
    [dispatch]
  );

  const bgImgUrl = 'https://oa-flight-assets.s3.ap-south-1.amazonaws.com/offer/top-container.png';

  return (
    <Box sx={flightContainerStyles}>
      <OAHeader
        title={''}
        appBarSx={{
          ...getHeaderStyles(
            premiumUserFeat,
            window?.innerHeight || 0,
            window?.innerWidth || 0
          ),
          width: '100%'
        }}
        action={<HeaderActionFlight totalActiveBookings={activeBookingsCount} />}
        backgroundImageText={
          <CoinsBanner
            title={premiumUserFeat ? 'Flights' : 'Book flights with'}
            subTitle={
              premiumUserFeat ? 'Redeem Niyo coins with additional discount' : '₹0 convenience fee'
            }
            titleSx={{
              color: '#FFFFFF',
              fontWeight: premiumUserFeat ? 600 : 400,
              fontSize: premiumUserFeat ? '30px' : '25px',
              lineHeight: premiumUserFeat ? '40px' : '35px',
            }}
            subTitleSx={{
              color: premiumUserFeat ? '#FDFDFD' : '#FFFFFF',
              mt: '10px',
              fontWeight: premiumUserFeat ? 400 : 600,
              fontSize: premiumUserFeat ? '14px' : '30px',
              lineHeight: premiumUserFeat ? '20px' : '40px',
            }}
          />
        }
      />

      <Container
        sx={{
          borderRadius: '20px 20px 0px 0px',
          position: 'relative',
          mt: '-35px', // Pull the container up to overlap the header
          backgroundColor: 'white', // Ensure that the container background is white to overlay
        }}
      >
        <SearchParameters shouldFetchLocations={true} setScrollTop={setScrollTop} />
        {/* <Box mt="30px">
          <CoinsBanner />
        </Box> */}
      </Container>
      <SpecialOffers />
      {isBannerDisplayed && <OffersBenefits />}
      {/* <Container sx={{ py: '30px' }}>
        <OAImage folder="icons" src="WhyUsBanner.svg" alt="whyUs" width="100%" />
      </Container> */}
      <Box
        sx={{
          mb: '30px',
          mt: isBannerDisplayed ? 0 : '30px',
        }}
      >
        <RecentFlightSearch />
      </Box>

      {isTestEmailForProd(user?.email) && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            textAlign: 'center',
            mt: '24px',
            p: '10px',
            color: 'grey.800',
            bgcolor: 'grey.500',
            position: 'fixed',
            bottom: 0,
            left: 0,
            width: '100%',
          }}
        >
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Version {VERSION}
          </Typography>

          <Box onClick={() => navigator.clipboard.writeText(token)} ml="24px">
            (Token)
          </Box>

          <Box ml="24px">
            <OASwitch onChange={handleOnChange} checked={isMock} />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Flight;
