import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Card, Container, Typography } from '@mui/material';
import { OAHeader, OAEmpty, OAImage } from '../../components';
import OATabSlide from '../../components/basic/OATabSlide';
import BookingCard from './BookingCard';
import BookingCardSkeleton from './BookingCardSkeleton';
import { useGetBookingsListQuery } from '../../services/bookingsApi';
import { theme } from '../../config';
import { useDispatch, useSelector } from '../../store';
import { getUserInputState, setBookingsTab } from '../../store/slices/userInputSlice';
import { eventsTracker } from '../../utils/ctEventsTracking';
import { usePostHog } from 'posthog-js/react';
import { getAuthInfo } from '../../store/slices/authSlice';
import { getUserInfo } from '../../store/slices/userInfoSlice';
import { getFeatureFlag } from '../../store/slices/featuresSlice';

const MyBookings = () => {
  const posthog = usePostHog();
  const { data, isError, isLoading } = useGetBookingsListQuery({});
  const { tags } = useSelector(getUserInfo);
  const { premiumUserFeat } = useSelector(getFeatureFlag);
  const { campaigns } = useSelector(getAuthInfo);
  const url = 'https://www.goniyo.com/oa/premium?spoke=flight';
  const { selectedBookingsTab } = useSelector(getUserInputState);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showBanner, setShowBanner] = useState<boolean>(false);
  const [startTime, setStartTime] = useState(Date.now());

  const PREMIUM_USER_TAGS = [
    'NIYO_GOLD_PREMIUM_USER',
    'NIYO_SILVER_PREMIUM_USER',
    'NIYO_PREMIUM_USER',
  ];

  const isPremiumUser = Boolean(
    Array.isArray(tags) &&
      tags?.length > 0 &&
      tags.some((tag) => typeof tag === 'string' && PREMIUM_USER_TAGS.includes(tag))
  );
  
  const completedBookings = data?.filter(
    (booking: any) =>
      booking?.bookingStatus === 'BOOKING_CONFIRMED' && booking?.orderCurrentStatus === 'COMPLETED'
  );
  const upcomingBookings = data?.filter(
    (booking: any) =>
      (['BOOKING_CONFIRMED', 'CANCELLATION_REQUESTED'].includes(booking?.bookingStatus) &&
        booking?.orderCurrentStatus === 'UPCOMING') ||
      booking?.bookingStatus === 'BOOKING_IN_PROGRESS' ||
      booking?.bookingStatus === 'PROCESSING_OFFLINE'
  );
  const cancelledBookings = data?.filter((booking: any) => booking?.bookingStatus === 'CANCELLED');
  const failedBookings = data?.filter(
    (booking: any) =>
      booking?.bookingStatus === 'BOOKING_FAILED' || booking?.bookingStatus === 'PAYMENT_FAILED'
  );

  const bookingsByTab: { [key: string]: any[] } = {
    COMPLETED: completedBookings || [],
    UPCOMING: upcomingBookings || [],
    CANCELLED: cancelledBookings || [],
    FAILED: failedBookings || [],
  };

  useEffect(() => {
    if (selectedBookingsTab === 'UPCOMING') {
      setShowBanner(true);
    } else {
      setShowBanner(false);
    }
  }, [selectedBookingsTab]);

  useEffect(() => {
    setStartTime(Date.now());
  }, []);

  const eventFreshChat = () => {
    if (
      window.flutter_inappwebview &&
      window.niyo_fresh_chat &&
      typeof window.niyo_fresh_chat === 'function'
    ) {
      let appSubStage = `${selectedBookingsTab?.toLowerCase()}`;

      const freshChatData = {
        appTag: 'oa_onarrival_mytrips',
        appSpoke: 'onarrival',
        appStage: 'onarrival_mytrips',
        appSubStage: `onarrival_mytrips_${appSubStage}`,
        faqTag: ['oa_onarrival_mytrips'],
      };
      const freshChatDataStringify = JSON.stringify(freshChatData);
      window.niyo_fresh_chat(freshChatDataStringify);
    } else {
      console.log('only allowed in mobile');
    }
  };

  const onOfferTap = () => {
    const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);
    eventsTracker(
      {
        flowName: 'Flight',
        screenName: 'Trips',
        ctaAction: 'Offers',
        screenDuration: totalScreenDuration?.toString(),
      },
      posthog
    );
    window.niyo_open_native_routes(url);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Box position="sticky" top="0" bgcolor="white" zIndex="1">
        <OAHeader
          title="My Trips"
          appBarSx={{ border: 'none' }}
          action={
            <Box onClick={eventFreshChat}>
              <OAImage src="chatbot.svg" folder="icons" alt="chat" />
            </Box>
          }
        />
      </Box>
      <OATabSlide
        selectedTab={selectedBookingsTab}
        tabValue={(newValue) => {
          const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);
          eventsTracker(
            {
              flowName: 'Flight',
              screenName: 'Trips',
              ctaAction: 'Type',
              screenDuration: totalScreenDuration?.toString(),
              otherData: {
                type: newValue,
              },
            },
            posthog
          );
          dispatch(setBookingsTab(newValue));
        }}
      />
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        {isError ? (
          <Container
            sx={{
              height: 'calc(100vh - 150px)',
              overflow: 'scroll',
              bgcolor: '#F4F6F5',
              py: '16px',
            }}
          >
            <OAEmpty
              illustration={
                <OAImage
                  src={
                    selectedBookingsTab === 'UPCOMING'
                      ? 'person-with-phone.svg'
                      : 'flight-unavailable.svg'
                  }
                  alt="unavailable"
                />
              }
              {...(selectedBookingsTab === 'UPCOMING' && { onClickBtn: () => navigate('/') })}
              bodytext={
                selectedBookingsTab === 'UPCOMING'
                  ? "You haven't made any bookings yet"
                  : selectedBookingsTab === 'COMPLETED'
                  ? 'There are no completed bookings yet'
                  : selectedBookingsTab === 'CANCELLED'
                  ? 'There are no cancelled bookings yet'
                  : selectedBookingsTab === 'FAILED'
                  ? 'There are no failed bookings yet'
                  : 'There are no past bookings yet'
              }
              buttonText="Book a Flight"
            />
          </Container>
        ) : isLoading ? (
          <Box>
            {Array.from({ length: 5 }).map((_, i) => (
              <BookingCardSkeleton key={i} />
            ))}
          </Box>
        ) : (
          <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
            {showBanner &&
              bookingsByTab?.[selectedBookingsTab]?.length > 0 &&
              campaigns?.eligibleCampaigns?.length > 0 && (
                <Box px="20px">
                  <Card
                    sx={{
                      display: 'flex',
                      marginTop: '16px',
                      marginBottom: '14px',
                      bgcolor: theme.palette.grey[500],
                      p: '10px',
                    }}
                  >
                    <OAImage
                      src="3xNiyo_coin.png"
                      alt="coins"
                      sx={{ mx: '5px', height: '18px', width: '18px' }}
                    />
                    <Typography ml="10px" variant="body3">
                      Your earned coins will be deposited in your wallet after your journey has been
                      completed
                    </Typography>
                  </Card>
                </Box>
              )}
            {bookingsByTab[selectedBookingsTab]?.length > 0 ? (
              bookingsByTab[selectedBookingsTab]?.map((booking: any, i: number) => (
                <BookingCard
                  key={booking.id || i}
                  data={booking}
                  selectedBookingsTab={selectedBookingsTab}
                />
              ))
            ) : (
              <Container
                sx={{
                  height: 'calc(100vh - 150px)',
                  overflow: 'scroll',
                  bgcolor: '#F4F6F5',
                  py: '16px',
                }}
              >
                <OAEmpty
                  {...(selectedBookingsTab === 'UPCOMING' && { onClickBtn: () => navigate('/') })}
                  bodytext={
                    selectedBookingsTab === 'UPCOMING'
                      ? "You haven't made any bookings yet"
                      : selectedBookingsTab === 'COMPLETED'
                      ? 'There are no completed bookings yet'
                      : selectedBookingsTab === 'CANCELLED'
                      ? 'There are no cancelled bookings yet'
                      : selectedBookingsTab === 'FAILED'
                      ? 'There are no failed bookings yet'
                      : 'There are no past bookings yet'
                  }
                  buttonText="Book a Flight"
                  illustration={
                    <OAImage
                      src={
                        selectedBookingsTab === 'UPCOMING'
                          ? 'person-with-phone.svg'
                          : 'flight-unavailable.svg'
                      }
                      alt="unavailable"
                    />
                  }
                />
              </Container>
            )}
          </Box>
        )}
      </Box>
      {!isPremiumUser && premiumUserFeat && (
        <Box
          sx={{
            position: 'sticky',
            bottom: 0,
            backgroundColor: '#132371',
            zIndex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            p: '10px 20px',
          }}
          onClick={onOfferTap}
        >
          <OAImage src="premium-gold-lg.svg" folder="offer" alt="grab-premium-offer" />
          <Typography variant="body2" ml="10px" color="#ECC65E">
            Grab ₹1000 off on your next Flight with us.
          </Typography>
          <OAImage ml="28px" src="arrow-right.svg" folder="icons" alt="ar" />
        </Box>
      )}
    </Box>
  );
};

export default MyBookings;
